<nav class="profile-nav">
    <ul class="profile-nav__list">
        <li class="profile-nav__item" *ngIf="showProfile">
            <a class="profile-nav__link esc-button is-tertiary is-tonal" [routerLink]="['', { outlets: { menu: null, primary: ['profil'] } }]">Mój profil</a>
        </li>
        <li class="profile-nav__item">
            <button class="profile-nav__link esc-button is-tertiary is-tonal" [ngClass]="{}" (click)="logout()">Wyloguj</button>
        </li>
    </ul>
</nav>
